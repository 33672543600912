<!-- Mto particular de Agencias Afectas -->

<template>
  <div class="agen_M" v-if="schema">
    <!-- Cabecera -->
      <div class="cabecera">
        <base_Header
          v-if="Entorno.header.header"       
          :Entorno="Entorno.header"
          @onEvent="event_Header">
        </base_Header>
      </div>

      <v-sheet :elevation="4" style="width:750px">
        <!-- Botones Mto -->
        <div class="pt-2 d-flex justify-left">

          <!-- Mto -->            
          <btramto
              :btra_permisos="schema.btra"
              :btra_particular="Entorno.btra"
              btra_modulo="m"
              :estado="estado"
              @onEvent="event_btra_Mto">        
          </btramto>

        </div>

        <div class="contenedor conflex">
          <div class="columna" style="width:70%">
            <ctrlfinder finderName="jefeszona_F" :schema="schema.ctrls.afecta_zona" :edicion="is_edit"></ctrlfinder>

            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.afecta_name.value"
              :label="schema.ctrls.afecta_name.label"
              :disabled="!is_edit">            
            </v-text-field>

            <div class="conflex">            
              <v-text-field
                style="flex: 1 1 75%"
                v-bind="$input"
                v-model="schema.ctrls.name.value"
                :label="schema.ctrls.name.label"
                :disabled="!is_edit"
              >
              </v-text-field>
            
              <v-text-field
                style="flex: 1 1 25%"
                v-bind="$input"
                v-model="schema.ctrls.cif.value"
                :label="schema.ctrls.cif.label"
                :disabled="!is_edit"
              >
              </v-text-field>              
            </div>

            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.cont1.value"
              :label="schema.ctrls.cont1.label"
              :disabled="!is_edit">            
            </v-text-field>

            <div class="conflex">
              <v-text-field
                style="flex: 1 1 80%"
                v-bind="$input"
                v-model="schema.ctrls.dire.value"
                :label="schema.ctrls.dire.label"
                :disabled="!is_edit"
              >
              </v-text-field>

              <v-text-field
                style="flex: 1 1 20%"
                v-bind="$input"
                v-model="schema.ctrls.cp.value"
                :label="schema.ctrls.cp.label"
                :disabled="!is_edit"
              >
              </v-text-field>
            </div>

            <div class="conflex">          
              <v-text-field
                style="flex: 1 1 50%"
                v-bind="$input"
                v-model="schema.ctrls.pob.value"
                :label="schema.ctrls.pob.label"
                :disabled="!is_edit"
              >
              </v-text-field>  

              <v-text-field
                style="flex: 1 1 50%"
                v-bind="$input"
                v-model="schema.ctrls.pro.value"
                :label="schema.ctrls.pro.label"
                :disabled="!is_edit"
              >
              </v-text-field>           
            </div>            

            <div class="conflex">
              <v-text-field
                style="flex: 1 1 33%"
                v-bind="$input"
                v-model="schema.ctrls.tlf1.value"
                :label="schema.ctrls.tlf1.label"
                :disabled="!is_edit"
              >
              </v-text-field>
            
              <v-text-field
                style="flex: 1 1 33%"
                v-bind="$input"
                v-model="schema.ctrls.tlf3.value"
                :label="schema.ctrls.tlf3.label"
                :disabled="!is_edit"
              >
              </v-text-field>
            
              <v-text-field
                style="flex: 1 1 33%"
                v-bind="$input"
                v-model="schema.ctrls.fax1.value"
                :label="schema.ctrls.fax1.label"
                :disabled="!is_edit"
              >
              </v-text-field>              
            </div>

            <div class="conflex">
              <v-text-field
                style="flex: 1 1 33%"
                v-bind="$input"
                v-model="schema.ctrls.tlf2.value"
                :label="schema.ctrls.tlf2.label"
                :disabled="!is_edit"
              >
              </v-text-field>
            
              <v-text-field
                style="flex: 1 1 33%"
                v-bind="$input"
                v-model="schema.ctrls.tlf4.value"
                :label="schema.ctrls.tlf4.label"
                :disabled="!is_edit"
              >
              </v-text-field>
            
              <v-text-field
                style="flex: 1 1 33%"
                v-bind="$input"
                v-model="schema.ctrls.fax2.value"
                :label="schema.ctrls.fax2.label"
                :disabled="!is_edit"
              >
              </v-text-field>            
            </div>

            <div class="conflex">
              <v-text-field
                style="flex: 1 1 50%"
                v-bind="$input"
                v-model="schema.ctrls.email1.value"
                :label="schema.ctrls.email1.label"
                :disabled="!is_edit"
              >
              </v-text-field>

              <v-text-field
                style="flex: 1 1 50%"
                v-bind="$input"
                v-model="schema.ctrls.email2.value"
                :label="schema.ctrls.email2.label"
                :disabled="!is_edit"
              >
              </v-text-field>
            </div>

            <v-textarea
              v-bind="$textarea"
              v-model="schema.ctrls.obs.value"
              :label="schema.ctrls.obs.label"
              no-resize
              rows="4"
              :disabled="!is_edit"
            >
            </v-textarea>

            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.cta.value"
              :label="schema.ctrls.cta.label"
              :disabled="!is_edit"
            >
            </v-text-field>

            <ctrlfinder :schema="schema.ctrls.afecta_f" :edicion="false">
            </ctrlfinder>

            <v-select
              v-bind="$select"
              v-model="schema.ctrls.afecta_apd.value"
              :label="schema.ctrls.afecta_apd.label"
              :items="$store.state.datos_iniciales.afecta_tipo"
              :disabled="true"
              item-value="id"
              item-text="name">            
            </v-select>
          </div>

          <div class="columna list_claves" style="padding-left:10px;width:30%">
            <div class="cab">CLAVES PRODUCCIÓN</div>
            <v-sheet v-bind="$cfg.styles.marco">
              <div class="conflex">
                <v-btn 
                  icon 
                  @click="add_claves">
                    <v-icon>{{'mdi-plus'}}</v-icon>
                </v-btn>

                <v-btn 
                  icon 
                  style="margin-left:10px"
                  @click="delete_claves">
                    <v-icon>{{'mdi-minus'}}</v-icon>
                </v-btn>
              </div>

              <v-list 
                dense 
                style="max-height: 244px"
                class="overflow-y-auto">
                <v-list-item-group v-model="selected">
                <!-- <template v-for="(item, index) in itemsClaves"> -->
                  <v-list-item
                    v-for="(item, index) in itemsClaves"
                    :key="index"
                   >
                  
                      <v-list-item-content>
                        <v-list-item-title v-text="item.clave"></v-list-item-title>                   
                      </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
                
              </v-list>
            </v-sheet>          
        </div>
      </div> 
    </v-sheet>

    <!-- Componentes dinámicos -->      
    <v-dialog
      v-model="modal"
      content-class="modal"
      persistent>
        <div class="centrado">
          <component                 
            :is="componente_dinamico"
            :padre="stName"           
            :accion="accion_claves"
            :accionRow="record"
            componenteTipo="FC"
            @onEvent="$event.accion=='cerrar' || $event.accion==6? cerrar_dinamico() : ''"
            @updateClaves="updateClaves">
          </component>           
        </div>
    </v-dialog>    
  </div>
</template>



<script>

  import { mixinMto } from "@/mixins/mixinMto.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const btramto = () => plugs.groute("btramto_new.vue", "comp");
  const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");
  const agen_M_claves = () => plugs.groute("agen_M_claves.vue", "comp");

  export default {
    mixins: [mixinMto],
    components: { base_Header, btramto, ctrlfinder, agen_M_claves },
    props: {
      padre: { type:String, default: '' }, 
      accion: { type:[Number, String], default: ''},
      accionRow: { type:Object, default: null},
      disparo: { type:Boolean, default:false },      
      componenteTipo: { type:String, default:'M' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema: null,
        api:'agen_M',
        stName:'stMagen',
        disparoFinder:false,

        itemsClaves: [],
        selected:0,
        accion_claves:0,
        componente_dinamico:null,
        modal:false    
      };
    },

   
    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo del store en variables locales
        this.schema = this.$store.state[this.stName].schema;  

        // entorno
        this.Entorno.header.titulo = "Mantenimiento Agencias Afectas";
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo.standardM));
      },


      // acciones al cargar el record
      // actualizo array de claves de produccion
      record_cargado() {         
        this.itemsClaves= this.miSt.recordsDetalle[1];
      },


      //
      add_claves() {
        this.modal= true;
        this.accion_claves= 1;       
        this.componente_dinamico= 'agen_M_claves';
      },


      //
      delete_claves() {
        this.modal= true;
        this.accion_claves= 2;       
        this.componente_dinamico= 'agen_M_claves';
      },

      
      // cierro ventana de componente dinámico
      cerrar_dinamico() {
        this.modal= false;
        this.componente_dinamico= null;
      },


      // actualizo list de la zona recibida
      updateClaves(evt) {      
        this.itemsClaves= evt.record;
      },

    },


    watch: {
      selected() {
        if (this.selected== undefined) return;
        console.log('Sel: ', this.selected, this.itemsClaves[this.selected]);
      }
    }
  }
</script>



<style scoped>
  .list_claves .v-list-item {
    min-height:25px !important;
  }

  .list_claves .v-list-item__content {
    padding: 0px 0px !important;
  }
</style>